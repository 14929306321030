<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考务管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">考务列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="考务名称" class="searchboxItem ci-full">
              <span class="itemLabel">考务名称:</span>
              <el-input v-model="activityName" clearable size="small" placeholder="请输入考务名称"></el-input>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button type="primary" class="bgc-bv" round @click="handleEdit()">新增考务</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader">
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" min-width="100" />
              <el-table-column label="考务名称" align="center" show-overflow-tooltip prop="activityName" min-width="150" />
              <el-table-column label="培训工种" align="center" show-overflow-tooltip prop="occupationName"
                min-width="150" />
              <el-table-column label="培训等级" align="center" show-overflow-tooltip prop="levelCode" min-width="120">
                <template slot-scope="{ row }">
                  {{ $setDictionary("ET_TRAIN_LEVEL", row.levelCode) }}
                </template>
              </el-table-column>
              <el-table-column label="考试信息" align="center" min-width="300">
                <template slot-scope="{ row }">
                  <template v-if="row.examList.length > 0">
                    <div v-for="item in row.examList" :key="item.activityExamId">
                      {{ $setDictionary("EA_EXAM_SUBJECT_CODE", item.subjectCode) }} : {{ item.startTime }} - {{ item.endTime }}
                    </div>
                  </template>
                  <template v-else> 无 </template>
                </template>
              </el-table-column>
              <el-table-column label="考试人数" align="center" show-overflow-tooltip prop="examineeNum" min-width="100" />
              <el-table-column label="操作" align="center" min-width="150" fixed="right">
                <div slot-scope="{ row }" class="flexcc">
                  <el-button type="text" style="padding: 0px 5px" size="mini"
                    @click="handleEdit(row.activityId)">编辑</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini"
                    @click="studentsChoose(row.activityId)">选择学员</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini"
                    @click="remove(row.activityId)">删除</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "appraisalInstitution/examList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      activityName: "", //考试名称
    };
  },
  created() { },
  mounted() {
    this.getTableHeight();
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.activityName) {
        params.activityName = this.activityName;
      }
      this.doFetch(
        {
          url: "/biz/examination_affairs/activity/pageList",
          params,
          pageNum,
        },
        true,
        6
      );
    },
    // 新增编辑
    handleEdit(activityId) {
      this.$router.push({
        path: "/web/appraisalInstitution/stuExam",
        query: { activityId },
      });
    },
    // 选择学员
    studentsChoose(activityId) {
      this.$router.push({
        path: "/web/appraisalInstitution/exam/chooseStudentList",
        query: { activityId },
      });
    },
    // 删除
    remove(activityId) {
      this.$confirm(`是否删除该考试?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$post(
          "/biz/examination_affairs/activity/delete",
          { activityId },
          3000,
          true,
          6
        ).then((ret) => {
          this.$message.success("操作成功！");
          this.getData(this.pageNum);
        });
      });
    },
  },
  beforeRouteLeave: resetKeepAlive,
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // 通过 `vm` 访问组件实例
      if (from.path == "/web/appraisalInstitution/stuExam" || from.path == "/web/appraisalInstitution/exam/chooseStudentList") {
        vm.getData(vm.pageNum);
      }
    });
  },
};
</script>

<style lang="less" scoped>
.searchBox {
  padding: 0 !important;

  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
</style>
